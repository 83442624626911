<template>
    <div class="banner">
        <div class="banner_con">
            <div class="banner_con_top">
                <el-dropdown @command="onCommand" style="margin-left: 20px" trigger="click">
                    <div class="banner_con_top_left">
                        <div>全部</div>
                        <img src="../assets/icon／arrow／down@3x (1).png" alt="" class="banner_con_top_left_img" />
                    </div>
                    <el-dropdown-menu slot="dropdown" style="width: 110px; text-align: center">
                        <el-dropdown-item command="supplySide" style="word-break: keep-all">供给侧 </el-dropdown-item>
                        <el-dropdown-item command="demandSide" style="word-break: keep-all">需求侧 </el-dropdown-item>
                        <el-dropdown-item command="successStories" style="word-break: keep-all"
                            >成功案例
                        </el-dropdown-item>
                        <el-dropdown-item command="information" style="word-break: keep-all"
                            >知产咨询动态
                        </el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
                <!-- <div class="banner_con_top_left">
                    <div>全部</div>
                    <img src="../assets/icon／arrow／down@3x (1).png" alt="" class="banner_con_top_left_img" />
                </div> -->
                <div class="banner_con_top_right" @click="all('/Allsearch')">
                    <div class="banner_con_top_right_title">请输入搜索内容</div>
                    <div class="banner_con_top_right_con">
                        <img src="../assets/icon／search@3x.png" alt="" class="banner_con_top_right_img" />
                    </div>
                </div>
            </div>
            <div class="banner_con_titleOne">
                <div class="banner_con_titleOne_con banner_con_titleOne_con_one" @click="supplySide">
                    <img src="../assets/png-gongjice-bg@3x.png" alt="" class="banner_con_titleOne_img" />
                    <div class="supply_side">
                        <div class="supply_side_one">供给侧</div>
                        <div class="supply_side_two">汇聚先进技术资源，助力企业升级</div>
                    </div>
                </div>
                <div class="banner_con_titleOne_con banner_con_titleOne_con_two" @click="demandSide">
                    <div class="supply_side">
                        <div class="supply_side_one">需求侧</div>
                        <div class="supply_side_two">加快科技成果转化，释放创新驱动效能</div>
                    </div>
                    <img src="../assets/png-xuqiuce-bg@3x.png" alt="" class="banner_con_titleOne_img" />
                </div>
            </div>
            <div class="banner_con_titleTwo">
                <div class="banner_con_titleTwo_con banner_con_titleTwo_con_one" @click="technology">
                    <img src="../assets/png-jishujingliren-bg@3x.png" alt="" class="banner_con_titleTwo_img" />
                    <div class="supply_sides">
                        <div class="supply_sides_one">技术经理人</div>
                        <div class="supply_sides_two">
                            立即查看<i class="el-icon-arrow-right" style="margin-left: 6px"></i>
                        </div>
                    </div>
                </div>
                <div class="banner_con_titleTwo_con banner_con_titleTwo_con_two" @click="transactionCase">
                    <img src="../assets/png-chengjiaoanli-bg@3x.png" alt="" class="banner_con_titleTwo_img" />
                    <div class="supply_sides">
                        <div class="supply_sides_one">成交案例</div>
                        <div class="supply_sides_two">
                            立即查看<i class="el-icon-arrow-right" style="margin-left: 6px"></i>
                        </div>
                    </div>
                </div>
                <div
                    class="banner_con_titleTwo_con banner_con_titleTwo_con_three"
                    @click="all('/agent?navigationId=95')"
                >
                    <img src="../assets/png-ipfuwu-bg@3x.png" alt="" class="banner_con_titleTwo_img" />
                    <div class="supply_sides">
                        <div class="supply_sides_one">知产运营服务</div>
                        <div class="supply_sides_two">
                            立即查看<i class="el-icon-arrow-right" style="margin-left: 6px"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- <swiper class="swiper" :options="swiperOption">
        <swiper-slide
            v-for="item in banners"
            :key="item.id"
            class="img"
            :style="{ backgroundImage: `url(${item.img})` }"
        >
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
    </swiper> -->
</template>

<script>
import { navTypeOptions } from '../utils/variables';
// import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
// import SearchInput from './SearchInput';
export default {
    data() {
        return {
            navTypeOptions,
            navigationId: 0,
            homeId: 0,
            banners: [],
            swiperOption: {
                watchOverflow: true,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true
                },
                autoplay: {
                    delay: 5000,
                    stopOnLastSlide: false,
                    disableOnInteraction: false
                }
            }
        };
    },
    mounted() {
        this.getBanner();
    },
    methods: {
        onCommand(command) {
            if (command == 'supplySide') {
                this.$store.commit('updateShowAdd', true);
                this.$router.push('/patent?caseType=GENERAL');
            } else if (command == 'demandSide') {
                this.$store.commit('updateShowAdd', true);
                this.$router.push('/patentDemand?caseType=GENERAL');
            } else if (command == 'successStories') {
                this.$store.commit('updateShowAdd', true);
                this.$router.push('/successCases?category=GENERAL');
            } else {
                this.$router.push('/newsList?flag=1');
            }
        },
        getBanner() {
            return this.$http
                .post(
                    '/banner/all',
                    {
                        sort: 'createdAt,desc'
                    },
                    {
                        body: 'json'
                    }
                )
                .then(res => {
                    this.banners = res.content;
                });
        },
        supplySide() {
            this.$store.commit('updateShowAdd', true);
            this.$router.push('/patent?caseType=GENERAL');
        },
        demandSide() {
            this.$store.commit('updateShowAdd', true);
            this.$router.push('/patentDemand?caseType=GENERAL');
        },
        technology() {
            this.$store.commit('updateShowAdd', true);
            this.$router.push('/technicalManager');
        },
        transactionCase() {
            this.$store.commit('updateShowAdd', true);
            this.$router.push('/successCases?category=GENERAL');
        }
    },
    // components: {
    //     Swiper,
    //     SwiperSlide,
    //     SearchInput
    // }
};
</script>

<style lang="less" scoped>
.appContainer {
    background: #ffffff !important;
}
.banner {
    width: 100%;
    height: 540px;
    background: linear-gradient(180deg, #01a041 1%, rgba(1, 160, 65, 0) 100%);
    padding: 60px 0px 21px;
    box-sizing: border-box;
    cursor: pointer;
    .banner_con {
        width: 1200px;
        margin: 0 auto;
        .banner_con_top {
            width: 646px;
            margin: 0 auto;
            display: flex;
            margin-bottom: 50px;
            .banner_con_top_left {
                width: 120px;
                height: 48px;
                background: #ffffff;
                border: 1px solid #009f40;
                margin-right: 1px;
                padding: 0px 12px 0px 16px;
                box-sizing: border-box;
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #727172;
                .banner_con_top_left_img {
                    width: 16px;
                    height: 16px;
                }
            }
            .banner_con_top_right {
                display: flex;
                height: 48px;
                align-items: center;
                border: 1px solid #009f40;
                background: #ffffff;
                box-sizing: border-box;
                .banner_con_top_right_title {
                    width: 450px;
                    height: 48px;
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #c8c9cc;
                    padding-left: 20px;
                    line-height: 48px;
                }
                .banner_con_top_right_con {
                    width: 75px;
                    height: 48px;
                    background: #009e40;
                    padding: 12px 0px 0px 26px;
                    box-sizing: border-box;
                    .banner_con_top_right_img {
                        width: 24px;
                        height: 24px;
                    }
                }
            }
        }
        .banner_con_titleOne {
            width: 1200px;
            display: flex;
            justify-content: space-between;
            margin-bottom: 20px;
            .banner_con_titleOne_img {
                width: 590px;
                height: 180px;
                // background: #3452fe;
            }
            .banner_con_titleOne_con {
                width: 590px;
                height: 180px;
                position: relative;
                .supply_side {
                    position: absolute;
                    top: 46px;
                    left: 30px;
                    .supply_side_one {
                        font-size: 24px;
                        font-weight: bold;
                        color: #ffffff;
                        line-height: 40px;
                        margin-bottom: 8px;
                    }
                    .supply_side_two {
                        font-size: 14px;
                        font-weight: 400;
                        color: #ffffff;
                        line-height: 24px;
                    }
                }
            }
        }
        .banner_con_titleTwo {
            width: 1200px;
            display: flex;
            justify-content: space-between;
            .banner_con_titleTwo_con {
                width: 387px;
                height: 161px;
                position: relative;
                .supply_sides {
                    position: absolute;
                    top: 46px;
                    left: 30px;
                    .supply_sides_one {
                        font-size: 20px;
                        font-weight: bold;
                        color: #ffffff;
                        line-height: 26px;
                        margin-bottom: 12px;
                    }
                    .supply_sides_two {
                        font-size: 14px;
                        font-weight: 400;
                        color: rgba(255, 255, 255, 0.6);
                        line-height: 24px;
                    }
                }
            }
            .banner_con_titleTwo_img {
                width: 387px;
                height: 161px;
                // background: #7d84ff;
            }
        }
    }
}
/deep/ .el-carousel__container {
    height: 420px;
    min-height: 420px;
}
.img {
    width: 100%;
    height: 26.25vw;
    min-height: 341px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    // display: block;
}
.el-dropdown-menu__item:not(.is-disabled):hover {
    color: #01a041;
}
.banner_con_titleOne_con_one {
    background: #3452fe;
}
.banner_con_titleOne_con_two {
    background: #009f40;
}
.banner_con_titleTwo_con_one {
    background: #7d84ff;
}
.banner_con_titleTwo_con_two {
    background: #bf7dff;
}
.banner_con_titleTwo_con_three {
    background: #7186ff;
}
</style>
